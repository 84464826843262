import React, { useRef } from "react";
import ReactHlsPlayer from "react-hls-player";
import "../muallimiSoni/harflar/harflar.css";

const VideoPlayer = ({ url, isPlaying, playerRef }) => {
  return (
    <div>
      <div className=" container player-wrapper">
        <ReactHlsPlayer
          url={`https://cdn.al-quran.uz/2/${
            url.uuid || "ef23dd92-b1d6-5238-860e-519aed0b6c15"
          }`}
          className="react-player-harflar"
          autoPlay={true}
          controls={true}
          width="100%"
          height="100%"
          playerRef={playerRef}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;
