import React, { useState, useEffect, useRef } from "react";
import { Layout } from "antd";
import { Row, Col } from "antd";
import axios from "axios";
import "../tajvid/tajvid.css";
import { tajvidList_url } from "../../../constants/BaseUrl";
import TajvidList from "./TajvidList";
import TajvidVideoPlayer from "../tajvid/TajvidVideoPlayer";

const Tajvid = () => {
  const [tajvidList, setTajvidList] = useState();
  const [getTajvidUrl, setTajvidUrl] = useState("");
  const [getTajvidActiveId, setTajvidActiveId] = useState(47);
  const { Content } = Layout;
  const playerRef = useRef();

  useEffect(async () => {
    const res = await axios.get(tajvidList_url);
    setTajvidList(res.data.data);
  }, [setTajvidList]);

  // const handlePlayTajvid = (url) => {
  //   setTajvidUrl(url);
  //   setTajvidActiveId(url.id);
  // };

  //to handle play videos
  const handlePlayTajvid = (url) => {
    if (url.id === getTajvidActiveId) {
      if (playerRef.current) {
        const video = playerRef.current;
        const isVideoPlaying = !!(
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2
        );
        if (isVideoPlaying) {
          video.pause();
        } else {
          video.play();
        }
      }
    } else {
      setTajvidUrl(url);
      setTajvidActiveId(url.id);
    }
  };
  return (
    <>
      <Col xs={24} lg={18}>
        <div style={{ marginTop: "3%" }}>
          <Content>
            <TajvidVideoPlayer playerRef={playerRef} url={getTajvidUrl} />
          </Content>
        </div>
      </Col>
      <Col xs={24} lg={6}>
        <div className="videoList_tajvid">
          <div
            style={{
              overflowY: "auto",
              height: "90vh",
              width: "95%",
              margin: "0 auto",
            }}
          >
            <Content>
              <TajvidList
                getTajvidActiveId={getTajvidActiveId}
                tajvidList={tajvidList}
                handlePlayTajvid={handlePlayTajvid}
              />
            </Content>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Tajvid;
