import React from "react";
import { Row, Col } from "antd";
import "../quron/quron.css";
import { PlayCircleOutlined } from "@ant-design/icons";
import Loader from "react-loader-spinner";
import listIcon from "../../images/listIcon.png";
import greyIconList from "../../images/list_icon_grey.png";
import playingIcon from "../../images/playingIcon.png";
import stopedIcon from "../../images/stopedIcon.png";
const QuronList = ({ getQuronActiveId, quronList, handlePlayQuron }) => {
  if (!quronList || quronList.lenght === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Loader
          color="#00B273"
          type="Puff"
          height={150}
          width={150}
          timeout={3000} //3 secs
        />
      </div>
    );
  }
  return (
    <div style={{ padding: "5px" }}>
      {quronList.map((quron) => (
        <div
          key={quron.id}
          style={
            getQuronActiveId == quron.id
              ? {
                  height: "100%",
                  width: "100%",
                  borderBottom: "1px solid ",
                  background: "#00B273",
                  borderColor: "rgba(0, 0, 0, 0.2)",
                }
              : {
                  height: "100%",
                  width: "100%",
                  borderBottom: "1px solid ",
                  borderColor: "rgba(0, 0, 0, 0.2)",
                }
          }
        >
          <Row style={{ display: "flex" }}>
            <div
              onClick={() => handlePlayQuron(quron)}
              style={{
                display: "flex",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div>
                {getQuronActiveId == quron.id ? (
                  <img
                    className="icon-quran"
                    src={listIcon}
                    style={{ padding: "10px" }}
                    alt="fdd"
                  />
                ) : (
                  <img
                    className="icon-quran"
                    src={greyIconList}
                    style={{ padding: "10px" }}
                    alt="fddd"
                  />
                )}
              </div>
              <div
                style={
                  getQuronActiveId == quron.id
                    ? {
                        fontSize: "18px",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        width: "600px",
                        justifyContent: "flex-start",
                      }
                    : {
                        fontSize: "18px",
                        color: "#000",
                        display: "flex",
                        alignItems: "center",
                        width: "600px",
                        justifyContent: "flex-start",
                      }
                }
              >
                {quron.name}
              </div>
              <div className="list_content_quron">
                <div className="content_inner_quron">
                  <div>
                    {getQuronActiveId == quron.id ? (
                      <img
                        src={stopedIcon}
                        style={{ padding: "10px" }}
                        alt="fdd"
                      />
                    ) : (
                      <img
                        src={playingIcon}
                        style={{ padding: "10px" }}
                        alt="fddd"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default QuronList;
