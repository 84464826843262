import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import axios from "axios";
// import "../zikirlar/zikrlar.css";
import { Row, Col, Button, Layout } from "antd";
import { zikirlarList_url } from "../../../constants/BaseUrl";
import TongiZikirlarList from "../tongizikrlar/TongiZikirlarList";
import TongiZikirlarVideoPlayer from "../tongizikrlar/TongiZikirlarVideoPlayer";
import Suralar from "../../muallimiSoni/suralar/Suralar";

const TongiZikirlar = ({ history, match }) => {
  const [tongizikirlarList, setTongiZikirlarList] = useState();
  const [getTongiZikirlarUrl, setTongiZikirlarUrl] = useState("");
  const [getTongiZikirlarActiveId, setTongiZikirlarActiveId] = useState(241);
  const { Content } = Layout;
  const playerRef = useRef();

  useEffect(async () => {
    const res = await axios.get(zikirlarList_url);
    setTongiZikirlarList(res.data.data);
  }, [setTongiZikirlarUrl]);

  // const handlePlayTongiZikirlar = (url) => {
  //   setTongiZikirlarUrl(url);
  //   setTongiZikirlarActiveId(url.id);
  // };

  //to handle the play function
  const handlePlayTongiZikirlar = (url) => {
    // setPlaying(!isPlaying);
    if (url.id === getTongiZikirlarActiveId) {
      if (playerRef.current) {
        const video = playerRef.current;
        const isVideoPlaying = !!(
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2
        );
        if (isVideoPlaying) {
          video.pause();
        } else {
          video.play();
        }
        // isPlaying ? setPlaying(false) : setPlaying(true);
      }
    } else {
      setTongiZikirlarUrl(url);
      setTongiZikirlarActiveId(url.id);
    }
  };
  // console.log(zikirlarList);
  return (
    <>
      <Col xs={24} lg={18}>
        <div style={{ marginTop: "3%" }}>
          <Content>
            <TongiZikirlarVideoPlayer url={getTongiZikirlarUrl} />
          </Content>
        </div>
      </Col>
      <Col xs={24} lg={6}>
        <div className="suralarVideoList">
          <div
            style={{
              overflowY: "auto",
              height: "90vh",
              width: "95%",
              margin: "0 auto",
            }}
          >
            <Content>
              <TongiZikirlarList
                getTongiZikirlarActiveId={getTongiZikirlarActiveId}
                tongizikirlarList={tongizikirlarList}
                handlePlayTongiZikirlar={handlePlayTongiZikirlar}
              />
            </Content>
          </div>
        </div>
      </Col>
    </>
  );
};

export default TongiZikirlar;
