import React, { useState, useEffect, useRef } from "react";
import "../suralar/suralar.css";
import { Layout } from "antd";
import { Row, Col } from "antd";
import axios from "axios";
import { suralarList_url } from "../../../constants/BaseUrl";
import SuralarVideoPlayer from "./SuralarVideoPlayer";
import SuralarList from "../suralar/SuralarList";
const Suralar = () => {
  const [suralarList, setSuralarList] = useState();
  const [getsuralarUrl, setSuralarUrl] = useState("");
  const [getSuralarActiveId, setSuralarActiveId] = useState(69);
  const { Content } = Layout;
  const playerRef = useRef();
  useEffect(async () => {
    const res = await axios.get(suralarList_url);
    setSuralarList(res.data.data);
  }, [setSuralarList]);

  // const handlePlaySuralar = (url) => {
  //   setSuralarUrl(url);
  //   setSuralarActiveId(url.id);
  // };

  const handlePlaySuralar = (url) => {
    if (url.id === getSuralarActiveId) {
      if (playerRef.current) {
        const video = playerRef.current;
        const isVideoPlaying = !!(
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2
        );
        if (isVideoPlaying) {
          video.pause();
        } else {
          video.play();
        }
      }
    } else {
      setSuralarUrl(url);
      setSuralarActiveId(url.id);
    }
  };

  return (
    <>
      <Col xs={24} lg={18}>
        <div style={{ marginTop: "3%" }}>
          <Content>
            <SuralarVideoPlayer playerRef={playerRef} url={getsuralarUrl} />
          </Content>
        </div>
      </Col>
      <Col xs={24} lg={6}>
        <div className="suralarVideoList">
          <div
            style={{
              overflowY: "auto",
              height: "90vh",
              width: "95%",
              margin: "0 auto",
            }}
          >
            <Content>
              <SuralarList
                getSuralarActiveId={getSuralarActiveId}
                suralarList={suralarList}
                handlePlaySuralar={handlePlaySuralar}
              />
            </Content>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Suralar;
