export const dayConverter = (prayerTime) => {
  if (prayerTime.date.gregorian.weekday.en === "Monday") {
    return "Dushanba";
  } else if (prayerTime.date.gregorian.weekday.en === "Tuesday") {
    return "Seshanba";
  } else if (prayerTime.date.gregorian.weekday.en === "Wednesday") {
    return "Chorshanba";
  } else if (prayerTime.date.gregorian.weekday.en === "Thursday") {
    return "Payshanba";
  } else if (prayerTime.date.gregorian.weekday.en === "Friday") {
    return "Juma";
  } else if (prayerTime.date.gregorian.weekday.en === "Saturday") {
    return "Shanba";
  } else if (prayerTime.date.gregorian.weekday.en === "Sunday") {
    return "Yakshanba";
  }
};

export const monthConverter = (prayerTime) => {
  if (prayerTime.date.gregorian.month.en === "January") {
    return "Yanvar";
  } else if (prayerTime.date.gregorian.month.en === "February") {
    return "Fevral";
  } else if (prayerTime.date.gregorian.month.en === "March") {
    return "Mart";
  } else if (prayerTime.date.gregorian.month.en === "April") {
    return "Aprel";
  } else if (prayerTime.date.gregorian.month.en === "May") {
    return "May";
  } else if (prayerTime.date.gregorian.month.en === "June") {
    return "Iyun";
  } else if (prayerTime.date.gregorian.month.en === "July") {
    return "Iyul";
  } else if (prayerTime.date.gregorian.month.en === "Aughust") {
    return "Avgust";
  } else if (prayerTime.date.gregorian.month.en === "September") {
    return "Sentabr";
  } else if (prayerTime.date.gregorian.month.en === "October") {
    return "Oktabr";
  } else if (prayerTime.date.gregorian.month.en === "November") {
    return "Noyabr";
  } else if (prayerTime.date.gregorian.month.en === "December") {
    return "Dekabr";
  }
};
