import { Route, Switch } from "react-router-dom";
import "./App.css";
import MainPage from "./components/main/MainPage";
import MuallimiSoniy from "./components/content/muallimiSoni/MuallimiSoniy";
import NamozVaqtlari from "./components/content/namozVaqtlari/NamozVaqtlari";
import Quron from "./components/content/quron/Quron";
import ZikirlarMain from "./components/content/zikirlar/zikrlarMain/ZikrlarMain";
// import TongiZikrlar from "./components/content/zikirlar/tongizikrlar/TongiZikirlar";
import Harflar from "./components/content/muallimiSoni/harflar/Harflar";
import Tajvid from "./components/content/muallimiSoni/tajvid/Tajvid";
import DiniyKalimalar from "./components/content/muallimiSoni/kalimalar/DiniyKalimalar";
import Suralar from "./components/content/muallimiSoni/suralar/Suralar";
function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/muallimiSoniy" component={MuallimiSoniy} />
        <Route path="/namozVaqtlari" component={NamozVaqtlari} />
        <Route path="/quron" component={Quron} />
        <Route path="/zikirlarmain" component={ZikirlarMain} />
        <Route path="/" component={MainPage} />
      </Switch>
    </div>
  );
}

export default App;
