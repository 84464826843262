import React from "react";
import ReactHlsPlayer from "react-hls-player";
import "../quron/quron.css";
const QuronVideoPlayer = ({ url, playerRef }) => {
  // console.log(url);
  return (
    <div>
      <div className=" container player-wrapper">
        <ReactHlsPlayer
          style={{ border: "1px solid black" }}
          url={`https://cdn.al-quran.uz/8/${
            url.uuid || "c1698e22-b14f-5923-bdc6-69dd92a48a82"
          }`}
          className="react-player-quron"
          playerRef={playerRef}
          autoPlay={true}
          controls={true}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default QuronVideoPlayer;
