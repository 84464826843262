import React from "react";
import ReactHlsPlayer from "react-hls-player";
import "../suralar/suralar.css";
const SuralarVideoPlayer = ({ url, playerRef }) => {
  return (
    <div>
      <div className=" container player-wrapper">
        <ReactHlsPlayer
          url={`https://cdn.al-quran.uz/6/${
            url.uuid || "d857a452-4770-5d88-92a7-48ad906c2b83"
          }`}
          style={{ border: "1px solid" }}
          className="react-player-suralar"
          autoPlay={true}
          controls={true}
          width="100%"
          height="100%"
          playerRef={playerRef}
        />
      </div>
    </div>
  );
};

export default SuralarVideoPlayer;
