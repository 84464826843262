import React from "react";
import Loader from "react-loader-spinner";
import { Row, Col } from "antd";
import "../tajvid/tajvid.css";
import { PlayCircleOutlined } from "@ant-design/icons";
import listIcon from "../../../images/listIcon.png";
import greyIconList from "../../../images/list_icon_grey.png";
import playingIcon from "../../../images/playingIcon.png";
import stopedIcon from "../../../images/stopedIcon.png";

const TajvidList = ({ getTajvidActiveId, tajvidList, handlePlayTajvid }) => {
  if (!tajvidList || tajvidList.lenght === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Loader
          color="#00B273"
          type="Puff"
          height={150}
          width={150}
          timeout={3000} //3 secs
        />
      </div>
    );
  }

  return (
    <div style={{ padding: "5px" }}>
      {tajvidList.map((tajvid) => (
        <div
          key={tajvid.id}
          style={
            getTajvidActiveId == tajvid.id
              ? {
                  height: "100%",
                  width: "100%",
                  borderBottom: "1px solid ",
                  background: "#00B273",
                  borderColor: "rgba(0, 0, 0, 0.2)",
                }
              : {
                  height: "100%",
                  width: "100%",
                  borderBottom: "1px solid ",
                  borderColor: "rgba(0, 0, 0, 0.2)",
                }
          }
        >
          <Row style={{ display: "flex" }}>
            <div
              onClick={() => handlePlayTajvid(tajvid)}
              style={{
                display: "flex",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div>
                {getTajvidActiveId == tajvid.id ? (
                  <img
                    src={listIcon}
                    style={{ padding: "10px" }}
                    alt="iconlist"
                  />
                ) : (
                  <img
                    src={greyIconList}
                    style={{ padding: "10px" }}
                    alt="iconlist"
                  />
                )}
              </div>
              <div
                className="tajvid_list"
                style={
                  getTajvidActiveId == tajvid.id
                    ? {
                        // fontSize: "21px",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }
                    : {
                        // fontSize: "21px",
                        color: "#000",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }
                }
              >
                {tajvid.name}
              </div>
              <div className="list_content_tajvid">
                <div
                  className="content_inner_tajvid"
                  // onClick={() => handlePlayTajvid(tajvid)}
                >
                  <div>
                    {getTajvidActiveId == tajvid.id ? (
                      <img
                        src={stopedIcon}
                        style={{ padding: "10px" }}
                        alt="fdd"
                      />
                    ) : (
                      <img
                        src={playingIcon}
                        style={{ padding: "10px" }}
                        alt="fddd"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default TajvidList;
