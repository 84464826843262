import React from "react";
import ReactHlsPlayer from "react-hls-player";
const TungiZikirlarVideoPlayer = ({ url }) => {
  // console.log(url);
  return (
    <div>
      <div className=" container player-wrapper">
        <ReactHlsPlayer
          style={{ border: "1px solid black" }}
          url={`https://cdn.al-quran.uz/12/${
            url.uuid || "575b4bf7-606e-5dbd-8a0b-5cbf90bf4499"
          }`}
          className="react-player-tungi"
          playing
          autoPlay={true}
          controls={true}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default TungiZikirlarVideoPlayer;
