import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import axios from "axios";
// import "../zikirlar/zikrlar.css";
import { Row, Col, Button, Layout } from "antd";
import { tungizikirlarList_url } from "../../../constants/BaseUrl";
import TungiZikrlarList from "./TungiZikrlarList";
import TungiZikrlarVideoPlayer from "./TungiZikrlarVideoPlayer";
const TungiZikirlar = ({ history, match }) => {
  const [tungizikirlarList, setTungiZikirlarList] = useState();
  const [getTungiZikirlarUrl, setTungiZikirlarUrl] = useState("");
  const [getTungiZikirlarActiveId, setTungiZikirlarActiveId] = useState(217);
  const { Content } = Layout;

  useEffect(async () => {
    const res = await axios.get(tungizikirlarList_url);
    setTungiZikirlarList(res.data.data);
  }, [setTungiZikirlarUrl]);

  const handlePlayTungiZikirlar = (url) => {
    setTungiZikirlarUrl(url);
    setTungiZikirlarActiveId(url.id);
  };
  // console.log(zikirlarList);
  return (
    <>
      <Col xs={24} lg={18}>
        <div style={{ marginTop: "3%" }}>
          <Content>
            <TungiZikrlarVideoPlayer url={getTungiZikirlarUrl} />
          </Content>
        </div>
      </Col>
      <Col xs={24} lg={6}>
        <div className="suralarVideoList">
          <div
            style={{
              overflowY: "auto",
              height: "90vh",
              width: "95%",
              margin: "0 auto",
            }}
          >
            <Content>
              <TungiZikrlarList
                getTungiZikirlarActiveId={getTungiZikirlarActiveId}
                tungizikirlarList={tungizikirlarList}
                handlePlayTungiZikirlar={handlePlayTungiZikirlar}
              />
            </Content>
          </div>
        </div>
      </Col>
    </>
  );
};

export default TungiZikirlar;
