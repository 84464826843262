import React from "react";
import Loader from "react-loader-spinner";
import { Row, Col } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import listIcon from "../../../images/listIcon.png";
import greyIconList from "../../../images/list_icon_grey.png";
import playingIcon from "../../../images/playingIcon.png";
import stopedIcon from "../../../images/stopedIcon.png";

const KalimalarList = ({ getKalimaActiveId, kalimaList, handlePlayKalima }) => {
  if (!kalimaList || kalimaList.lenght === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Loader
          color="#00B273"
          type="Puff"
          height={150}
          width={150}
          timeout={3000} //3 secs
        />
      </div>
    );
  }
  return (
    <div style={{ padding: "5px" }}>
      {kalimaList.map((kalima) => (
        <div
          key={kalima.id}
          style={
            getKalimaActiveId == kalima.id
              ? {
                  height: "100%",
                  width: "100%",
                  borderBottom: "1px solid ",
                  background: "#00B273",
                  borderColor: "rgba(0, 0, 0, 0.2)",
                }
              : {
                  height: "100%",
                  width: "100%",
                  borderBottom: "1px solid ",
                  borderColor: "rgba(0, 0, 0, 0.2)",
                }
          }
        >
          <Row style={{ display: "flex" }}>
            <div
              onClick={() => handlePlayKalima(kalima)}
              style={{
                display: "flex",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div>
                {getKalimaActiveId == kalima.id ? (
                  <img src={listIcon} style={{ padding: "10px" }} alt="fdd" />
                ) : (
                  <img
                    src={greyIconList}
                    style={{ padding: "10px" }}
                    alt="fddd"
                  />
                )}
              </div>
              <div
                className="kalimalar_list"
                style={
                  getKalimaActiveId == kalima.id
                    ? {
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }
                    : {
                        color: "#000",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }
                }
              >
                {kalima.name}
              </div>
              <div
                className="list_content_kalimalar"
                // onClick={() => handlePlayKalima(kalima)}
              >
                <div className="content_inner_kalimalar">
                  <div>
                    {getKalimaActiveId == kalima.id ? (
                      <img
                        src={stopedIcon}
                        style={{ padding: "10px" }}
                        alt="fdd"
                      />
                    ) : (
                      <img
                        src={playingIcon}
                        style={{ padding: "10px" }}
                        alt="fddd"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default KalimalarList;
