import React, { useState, useEffect, useRef } from "react";
import "../harflar/harflar.css";
import { Layout } from "antd";
import { Row, Col, Button } from "antd";
import axios from "axios";
import { harflarList_url } from "../../../constants/BaseUrl";
import VideoPlayer from "../../player/VideoPlayer";
import VideoList from "../../player/VideoList";
import stopedIcon from "../../../images/stopedIcon.png";
import playingIcon from "../../../images/playingIcon.png";

const Harflar = () => {
  const [harflarList, setHarflarList] = useState();
  const [getUrl, setUrl] = useState("");
  const [getActiveId, setActiveId] = useState(2);
  const [isPlaying, setPlaying] = useState(true);
  const [playingVideo, setPlayingVideo] = useState(true);
  const { Content } = Layout;
  const playerRef = useRef();

  useEffect(async () => {
    const res = await axios.get(harflarList_url);
    setHarflarList(res.data.data);
  }, [setHarflarList]);

  //to render the icon on the button
  const renderPlaybtn = (isPlaying) => {};

  //to handle the play function
  const handlePlay = (url) => {
    if (url.id === getActiveId) {
      if (playerRef.current) {
        const video = playerRef.current;
        const isVideoPlaying = !!(
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2
        );
        if (isVideoPlaying) {
          video.pause();
        } else {
          video.play();
        }
      }
    } else {
      setUrl(url);
      setActiveId(url.id);
    }
  };

  return (
    <>
      <Col xs={24} lg={18}>
        <div style={{ marginTop: "3%" }}>
          <Content>
            <VideoPlayer
              url={getUrl}
              isPlaying={isPlaying}
              playerRef={playerRef}
            />
          </Content>
        </div>
      </Col>
      <Col xs={24} lg={6}>
        <div className="videoList_harflar">
          <div
            style={{
              overflowY: "auto",
              height: "90vh",
              width: "95%",
              margin: "0 auto",
            }}
          >
            <Content>
              <VideoList
                getActiveId={getActiveId}
                harflarList={harflarList}
                handlePlay={handlePlay}
                renderPlaybtn={renderPlaybtn}
                isPlaying={isPlaying}
              />
            </Content>
          </div>
        </div>
      </Col>
    </>
  );
};

export default Harflar;
