import React from "react";
import ReactHlsPlayer from "react-hls-player";
import "../kalimalar/kalimalar.css";
const KalimalarVideoPlayer = ({ url, playerRef }) => {
  return (
    <div>
      <div className=" container player-wrapper">
        <ReactHlsPlayer
          url={`https://cdn.al-quran.uz/3/${
            url.uuid || "9d5a32cb-7572-5da6-b814-4c6e6b0a5d32"
          }`}
          className="react-player-kalimalar"
          playing
          autoPlay={true}
          controls={true}
          width="100%"
          height="100%"
          playerRef={playerRef}
        />
      </div>
    </div>
  );
};

export default KalimalarVideoPlayer;
