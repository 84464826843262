import React, { useState, useEffect, useRef } from "react";
import "../kalimalar/kalimalar.css";
import { Layout } from "antd";
import { Row, Col } from "antd";
import axios from "axios";
import { kalimaList_url } from "../../../constants/BaseUrl";
import KalimalarVideoList from "../kalimalar/KalimalarVideoPlayer";
import KalimalarList from "./KalimalarList";

const DiniyKalimalar = () => {
  const [kalimaList, setKalimaList] = useState();
  const [getKalimaUrl, setKalimaUrl] = useState("");
  const [getKalimaActiveId, setKalimaActiveId] = useState(34);
  const { Content } = Layout;
  const playerRef = useRef();

  useEffect(async () => {
    const res = await axios.get(kalimaList_url);
    setKalimaList(res.data.data);
  }, [setKalimaList]);

  const handlePlayKalima = (url) => {
    if (url.id === getKalimaActiveId) {
      if (playerRef.current) {
        const video = playerRef.current;
        const isVideoPlaying = !!(
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2
        );
        if (isVideoPlaying) {
          video.pause();
        } else {
          video.play();
        }
      }
    } else {
      setKalimaUrl(url);
      setKalimaActiveId(url.id);
    }
  };
  return (
    <>
      <Col xs={24} lg={18}>
        <div style={{ marginTop: "3%" }}>
          <Content>
            <KalimalarVideoList playerRef={playerRef} url={getKalimaUrl} />
          </Content>
        </div>
      </Col>
      <Col xs={24} lg={6}>
        <div className="kalimalarVideoList">
          <div
            style={{
              overflowY: "auto",
              height: "90vh",
              width: "95%",
              margin: "0 auto",
            }}
          >
            <Content>
              <KalimalarList
                getKalimaActiveId={getKalimaActiveId}
                kalimaList={kalimaList}
                handlePlayKalima={handlePlayKalima}
              />
            </Content>
          </div>
        </div>
      </Col>
    </>
  );
};

export default DiniyKalimalar;
