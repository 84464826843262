import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Row, Col } from "antd";
import { base_url } from "../../constants/BaseUrl";
import "../muallimiSoni/muallimisoniy.css";
import Suralar from "./suralar/Suralar";
import Tajvid from "./tajvid/Tajvid";
import Harflar from "./harflar/Harflar";
import DiniyKalimalar from "./kalimalar/DiniyKalimalar";
import logoSvg from "../../images/logosvg.svg";

const MuallimiSoniy = ({ history, match }) => {
  // const [activeBtn, setActiveBtn] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [labels, setLabels] = useState("");
  const [btns, setBtns] = useState([
    { url: `${match.url}/harflar`, label: "Harflar" },
    {
      url: `${match.url}/tajvidqoidalari`,
      label: "Tajvid qoidalari",
    },
    { url: `${match.url}/kalimalar`, label: "Kalimalar" },
    { url: `${match.url}/suralar`, label: "Quron suralar" },
  ]);

  useEffect(() => {
    let url = history.location.pathname;
    let type = url.split("/")[url.split("/").length - 1];
    switch (type) {
      case "harflar":
        setActiveIndex(0);
        break;
      case "tajvidqoidalari":
        setActiveIndex(1);
        break;
      case "kalimalar":
        setActiveIndex(2);
        break;
      case "suralar":
        setActiveIndex(3);
        break;
      default:
        setActiveIndex(0);
        break;
    }
  }, []);

  const colorChange = (index) => {
    setActiveIndex(index);
    setLabels(btns[index].label);
    history.push(btns[index].url);
    console.log(labels);
  };

  return (
    <div>
      <div className="logo_div1">
        <img
          src={logoSvg}
          className="alquran_logo"
          onClick={() => history.push("/")}
        />
      </div>
      <Row className=" d-flex w-100 muallimiSoniy_div ">
        <Col sm={24} lg={18}>
          <div className="d-flex nav_div">
            <button
              onClick={() => colorChange(0)}
              className={
                activeIndex === 0
                  ? "activeBtn button_div"
                  : "not-active button_div"
              }
            >
              Harflar
            </button>
            <button
              onClick={() => colorChange(1)}
              className={
                activeIndex === 1
                  ? "activeBtn button_div"
                  : "not-active button_div"
              }
              // type="primary"
            >
              Tajvid qoidalari
            </button>
            <button
              onClick={() => colorChange(2)}
              className={
                activeIndex === 2
                  ? "activeBtn button_div"
                  : "not-active button_div"
              }
              // type="primary"
            >
              Diniy kalimalar
            </button>
            <button
              onClick={() => colorChange(3)}
              className={
                activeIndex === 3
                  ? "activeBtn button_div"
                  : "not-active button_div"
              }
              // type="primary"
            >
              Qur'on suralari
            </button>
          </div>
        </Col>
        <Col
          className="listTitle"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          sm={24}
          lg={6}
        >
          <div className="title_div ">
            <h5>{labels ? labels : "Harflar"}</h5>
          </div>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Switch>
          <Route path={`${match.url}/harflar`} component={Harflar} />
          <Route path={`${match.url}/tajvidqoidalari`} component={Tajvid} />
          <Route path={`${match.url}/kalimalar`} component={DiniyKalimalar} />
          <Route path={`${match.url}/suralar`} component={Suralar} />
          <Redirect from={`${match.url}`} to={`${match.url}/harflar`} />
        </Switch>
      </Row>
    </div>
  );
};

export default MuallimiSoniy;
