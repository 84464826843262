import React, { useRef } from "react";
import Loader from "react-loader-spinner";
import { Row, Col } from "antd";
import { PlayCircleOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./player.css";
import pause from "../../images/pause.svg";
import listIcon from "../../images/listIcon.png";
import greyIconList from "../../images/list_icon_grey.png";
import playingIcon from "../../images/playingIcon.png";
import stopedIcon from "../../images/stopedIcon.png";

const VideoList = ({
  isPlaying,
  renderPlaybtn,
  getActiveId,
  harflarList,
  handlePlay,
}) => {
  if (!harflarList || harflarList.lenght === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Loader
          color="#00B273"
          type="Puff"
          height={150}
          width={150}
          timeout={3000} //3 secs
        />
      </div>
    );
  }

  return (
    <div className="video_player" style={{ padding: "5px" }}>
      {harflarList.map((harflar) => (
        <div
          key={harflar.id}
          style={
            getActiveId == harflar.id
              ? {
                  height: "100%",
                  width: "100%",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.2) ",
                  background: "#00B273",
                }
              : {
                  height: "100%",
                  width: "100%",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                }
          }
        >
          <Row style={{ display: "flex" }}>
            <div
              onClick={() => handlePlay(harflar)}
              style={{
                display: "flex",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div>
                {getActiveId == harflar.id ? (
                  <img src={listIcon} style={{ padding: "10px" }} alt="fdd" />
                ) : (
                  <img
                    src={greyIconList}
                    style={{ padding: "10px" }}
                    alt="fddd"
                  />
                )}
              </div>
              <div
                style={
                  getActiveId == harflar.id
                    ? {
                        fontSize: "26px",
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                      }
                    : {
                        fontSize: "26px",
                        color: "#000",
                        display: "flex",
                        alignItems: "center",
                      }
                }
              >
                {harflar.name}
              </div>
              <div className="list_content_harflar">
                <div
                  className="content_inner_harflar"
                  // onClick={() => handlePlay(harflar)}
                >
                  <div>
                    {getActiveId == harflar.id || !isPlaying ? (
                      <img
                        src={stopedIcon}
                        style={{ padding: "10px" }}
                        alt="fdd"
                      />
                    ) : (
                      <img
                        src={playingIcon}
                        style={{ padding: "10px" }}
                        alt="fddd"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default VideoList;
