import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment, { min } from "moment";
import { Row, Col, Divider } from "antd";
import { getPrayerTime } from "../namozVaqtlari/api/api";
import { getNamozVaqtlari } from "../../constants/BaseUrl";
import { dayConverter, monthConverter } from "../../utils/timeAndDate";
import "../../content/namozVaqtlari/namozvaqtlari.css";
import Loader from "react-loader-spinner";
import locationIcon from "../../images/location-pin.png";
const NamozVaqtlari = ({ history }) => {
  const [currentTime, setCurrentTime] = useState("");
  const [prayerTime, setPrayerTime] = useState([]);
  const [namozVaqt, setNamozVaqt] = useState([]);
  const [prayerList, setPrayerList] = useState({});
  const [leftTime, setLeftTime] = useState("00:00:00");
  const [currentNamoz, setCurrentNamoz] = useState("Yuklanmoqda...");
  const [highlightTime, setHightTime] = useState(false);
  const [backhighlight, setBackHighlight] = useState(0);
  const [nextDay, setNextDay] = useState();

  //to fetch the data from the API
  useEffect(async () => {
    let year = moment().format("YYYY");
    let month = moment().format("MM");
    await getNamozVaqtlari(month, year).then((response) => {
      if (response.status === 200) {
        setNamozVaqt(response.data);
        findPrayerList(response.data);
      }
    });
  }, []);

  // here calling the handleLeftTime func repeatedly
  useEffect(() => {
    handleLeftTime();

    const interval = setInterval(() => {
      handleLeftTime();
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });
  // bu ham kerak....
  useEffect(() => {
    getPrayerTime().then((res) => {
      if (res.data.code === 200) {
        setPrayerTime(res.data.data);
      }
    });
  }, []);
  // to display the real time
  useEffect(() => {
    let current_date = moment().format("HH:mm");
    setCurrentTime(current_date);
    const interval = setInterval(() => {
      setCurrentTime(moment().format("HH:mm"));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  // to display the end time of the day
  const endDay = () => {
    let current = moment().format("MM-DD-YYYY");
    return moment(new Date(current + " 23:59:59")).format(
      "DD-MM-YYYY HH:mm:ss"
    );
  };
  // to display the start time of the day
  const beginDay = () => {
    let current = moment().format("MM-DD-YYYY");
    return moment(new Date(current + " 00:00:00")).format(
      "DD-MM-YYYY HH:mm:ss"
    );
  };
  // to display the list of namoz vaqtlari
  const findPrayerList = (data) => {
    if (data) {
      let currentDate = moment().format("DD-MM-YYYY");
      let pList = data.filter((prayer) => prayer.gregorian == currentDate);
      setPrayerList(pList[0]);
      setNextDay(data[data.indexOf(pList[0]) + 1]);
      highlight(pList[0]);
    } else return console.log("Internal Server error");
  };
  // to display the amount of time left for next namoz time
  const findLeftTime = (ms) => {
    if (!ms) {
      return "-00:00:00";
    }
    ms = Math.floor(ms / 1000);
    let hour = Math.floor(ms / 3600);
    let minute = Math.floor(ms / 60) % 60;
    let second = ms % 60;
    return (
      "-" +
      `${hour}`.padStart(2, "0") +
      ":" +
      `${minute}`.padStart(2, "0") +
      ":" +
      `${second}`.padStart(2, "0")
    );
  };
  // to make right date format
  const reverseString = (str) => {
    if (str) {
      var s = str.split("-");
      return s[1] + "-" + s[0] + "-" + s[2];
    }
    return "";
  };
  // to detect the current namoz
  const handleLeftTime = () => {
    let currentTimeNamoz = moment().format("HH:mm");
    if (
      currentTimeNamoz > prayerList.Sunrise &&
      currentTimeNamoz < prayerList.Dhuhr
    ) {
      setHightTime(true);
      setCurrentNamoz("Peshin");
      setLeftTime(
        findLeftTime(
          new Date(
            `${reverseString(prayerList.gregorian)} ${prayerList.Dhuhr}`
          ) - new Date()
        )
      );
    } else if (
      currentTimeNamoz > prayerList.Dhuhr &&
      currentTimeNamoz < prayerList.Asr
    ) {
      setHightTime(true);
      setCurrentNamoz("Asr");
      setLeftTime(
        findLeftTime(
          new Date(`${reverseString(prayerList.gregorian)} ${prayerList.Asr}`) -
            new Date()
        )
      );
    } else if (
      currentTimeNamoz > prayerList.Asr &&
      currentTimeNamoz < prayerList.Maghrib
    ) {
      setHightTime(true);
      setCurrentNamoz("Shom");
      setLeftTime(
        findLeftTime(
          new Date(
            `${reverseString(prayerList.gregorian)} ${prayerList.Maghrib}`
          ) - new Date()
        )
      );
    } else if (
      currentTimeNamoz > prayerList.Maghrib &&
      currentTimeNamoz < prayerList.Isha
    ) {
      setHightTime(true);
      setCurrentNamoz("Xufton");
      setLeftTime(
        findLeftTime(
          new Date(
            `${reverseString(prayerList.gregorian)} ${prayerList.Isha}`
          ) - new Date()
        )
      );
    } else if (
      currentTimeNamoz > prayerList.Isha &&
      currentTimeNamoz < endDay()
    ) {
      setHightTime(true);
      setCurrentNamoz("Bomdod");

      let tmp = findLeftTime(
        new Date(`${reverseString(nextDay.gregorian)} ${nextDay.Fajr}`) -
          new Date()
      );

      setLeftTime(tmp);
    } else if (
      // currentTimeNamoz > prayerList.Isha &&
      currentTimeNamoz > beginDay()
    ) {
      setHightTime(true);
      setCurrentNamoz("Bomdod");

      let tmp = findLeftTime(
        new Date(`${reverseString(prayerList.gregorian)} ${prayerList.Fajr}`) -
          new Date()
      );
      setLeftTime(tmp);
    }
  };
  //to highlight the chosen namoz label
  const highlight = (prayerList) => {
    let currentTimeNamoz = moment().format("HH:mm");

    if (
      currentTimeNamoz < prayerList.Sunrise &&
      currentTimeNamoz >= prayerList.Fajr
    ) {
      setBackHighlight(1);
    } else if (
      currentTimeNamoz >= prayerList.Dhuhr &&
      currentTimeNamoz < prayerList.Asr
    ) {
      setBackHighlight(3);
    } else if (
      currentTimeNamoz >= prayerList.Asr &&
      currentTimeNamoz < prayerList.Maghrib
    ) {
      setBackHighlight(4);
    } else if (
      currentTimeNamoz >= prayerList.Maghrib &&
      currentTimeNamoz < prayerList.Isha
    ) {
      setBackHighlight(5);
    } else if (
      currentTimeNamoz >= prayerList.Isha &&
      currentTimeNamoz < prayerList.Isha
    ) {
      setBackHighlight(6);
    }
  };
  const findAmPmNamoz = (time) => {
    if (time > "11:59:59") return "PM";
    else return "AM";
  };

  // here displaying the jsx
  if (prayerTime.timings && prayerTime.date) {
    const data1 = [
      { id: 1, name: "Bomdod", time: prayerList.Fajr },
      { id: 2, name: "Quyosh", time: prayerList.Sunrise },
      { id: 3, name: "Peshin", time: prayerList.Dhuhr },
      { id: 4, name: "Asr", time: prayerList.Asr },
      { id: 5, name: "Shom", time: prayerList.Maghrib },
      { id: 6, name: "Xufton", time: prayerList.Fajr },
    ];

    const getNearestNamozTime = () => {
      switch (currentNamoz) {
        case "Bomdod":
          return prayerList.Fajr;
        case "Quyosh":
          return prayerList.Sunrise;
        case "Peshin":
          return prayerList.Dhuhr;
        case "Asr":
          return prayerList.Asr;
        case "Shom":
          return prayerList.Maghrib;
        case "Xufton":
          return prayerList.Isha;
        default:
          return "...";
      }
    };
    return (
      <div className="w-100 namoz_main_class">
        <div className="namoz_row0 d-flex w-100 ">
          <div className="namoz_row0_left">
            <h6 style={{ fontWeight: "bold" }}>{`${
              prayerTime.date.gregorian.day
            }- ${monthConverter(prayerTime)}`}</h6>
            <h6>
              {prayerTime.date.hijri.year}-yil {prayerTime.date.hijri.day}{" "}
              {prayerTime.date.hijri.month.en}
            </h6>
          </div>
          <div className="namoz_row0_right">
            <h4>{currentNamoz}</h4>
            <h3>{getNearestNamozTime()}</h3>
            <h6>{leftTime}</h6>
            <h6>
              Tashkent, Uzbekistan
              <span>
                <img
                  src={locationIcon}
                  alt="location"
                  className="namoz_locationIcon"
                />
              </span>
            </h6>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div className="namoz_table shadow-lg">
            {prayerTime &&
              data1.map((nv) => (
                <Row
                  style={{ borderRadius: "8px" }}
                  className={
                    backhighlight === nv.id
                      ? "namozClass-active"
                      : "namozClass-inactive"
                  }
                  justify="space-between"
                  key={nv.id}
                >
                  <Col>
                    <div
                      className="namoz_text_up"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div>
                        <div
                          className="text-warning namoz_text "
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {nv.name}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <div
                      className="namoz_text_up"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div>
                        <div
                          className="text-warning namoz_text  "
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {nv.time}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Loader
          color="rgb(247,194,68)"
          type="Puff"
          height={150}
          width={150}
          timeout={3000} //3 secs
        />
      </div>
    );
  }
};

export default withRouter(NamozVaqtlari);
