import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Row, Col } from "antd";
import "../zikrlarMain/zikrlarmain.css";
import TongiZikirlar from "../tongizikrlar/TongiZikirlar";
import TungiZikrlar from "../tungizikrlar/TungiZikrlar";
import logoSvg from "../../../images/logosvg.svg";
const ZikrlarMain = ({ history, match }) => {
  const [activeZikrBtn, setActiveZikrBtn] = useState(0);
  const [zikrLabel, setZikrLabel] = useState("");
  const [activeZikrs, setActiveZikrs] = useState([
    { url: `${match.url}/tongizikrlar`, labelZikr: "Tongi zikrlar" },
    { url: `${match.url}/tungizikrlar`, labelZikr: "Tungi zikrlar" },
  ]);

  useEffect(() => {
    let url = history.location.pathname;
    let type = url.split("/")[url.split("/").length - 1];
    if (type == "tungizikrlar") setActiveZikrBtn(1);
    else setActiveZikrBtn(0);
  }, []);
  const handleActiveZikr = (index) => {
    setActiveZikrBtn(index);
    setZikrLabel(activeZikrs[index].labelZikr);
    history.push(activeZikrs[index].url);
  };
  return (
    <div>
      <div className="logo_div2">
        <img
          src={logoSvg}
          className="alquran_logo2"
          onClick={() => history.push("/")}
        />
      </div>
      <Row className="d-flex w-100 zikrlar_main_div">
        <Col sm={24} lg={18}>
          <div className="d-flex zikr_main_nav_div">
            <button
              onClick={() => handleActiveZikr(0)}
              className={
                activeZikrBtn === 0
                  ? "activeBtn zikr_main_button"
                  : "not-active zikr_main_button"
              }
            >
              Tongi zikrlar
            </button>
            <button
              onClick={() => handleActiveZikr(1)}
              className={
                activeZikrBtn === 1
                  ? "activeBtn zikr_main_button"
                  : "not-active zikr_main_button"
              }
            >
              Tungi zikrlar
            </button>
          </div>
        </Col>
        <Col
          sm={24}
          lg={6}
          className="zikr_list_title_div"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="zikr_list_title">
            <div>{zikrLabel ? zikrLabel : "Tongi zikrlar"}</div>
          </div>
        </Col>
      </Row>
      <Row
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Switch>
          <Route
            path={`${match.url}/tongizikirlar`}
            component={TongiZikirlar}
          />
          <Route path={`${match.url}/tungizikrlar`} component={TungiZikrlar} />
          <Redirect from={`${match.url}`} to={`${match.url}/tongizikirlar`} />
        </Switch>
      </Row>
    </div>
  );
};

export default ZikrlarMain;
