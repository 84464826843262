import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { getPrayerTime } from "../content/namozVaqtlari/api/api";
import { getNamozVaqtlari } from "../constants/BaseUrl";
import { dayConverter, monthConverter } from "../utils/timeAndDate";
import "../main/main.css";
import Loader from "react-loader-spinner";
import locationIcon from "../images/location-pin.png";
import namozvaqtiBack from "../images/namozvaqtiBack.png";
import muallimisoniyBack from "../images/muallimisoniyBack.png";
import quronBack from "../images/quronBack.png";
import zikrlarBack from "../images/zikrlarBack.png";
const MainPage = ({ history }) => {
  const [currentTime, setCurrentTime] = useState("");
  const [prayerTime, setPrayerTime] = useState([]);
  const [namozVaqtMain, setNamozVaqtMain] = useState([]);
  const [prayerListMain, setPrayerListMain] = useState({});
  const [nextDayMain, setNextDayMain] = useState();
  const [currentNamozMain, setCurrentNamozMain] = useState("Yuklanmoqda...");
  const [leftTimeMain, setLeftTimeMain] = useState("00:00:00");
  //to fetch the data from the API
  useEffect(async () => {
    let year = moment().format("YYYY");
    let month = moment().format("MM");
    await getNamozVaqtlari(month, year).then((res) => {
      if (res.status === 200) {
        setNamozVaqtMain(res.data);
        findPrayerListMain(res.data);
        // endDayMain();
      }
    });
  }, []);
  //times on the left side
  useEffect(() => {
    getPrayerTime().then((res) => {
      if (res.data.code === 200) {
        setPrayerTime(res.data.data);
      }
    });
  }, []);
  //to display the time
  useEffect(() => {
    let current_date = moment().format("HH:mm");
    setCurrentTime(current_date);
    const interval = setInterval(() => {
      setCurrentTime(moment().format("HH:mm"));
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // console.log(prayerTime);
  useEffect(() => {
    getPrayerTime().then((res) => {
      if (res.data.code === 200) {
        setPrayerTime(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    let current_date = moment().format("HH:mm");
    setCurrentTime(current_date);
    handleLeftTimeMain();
    const interval = setInterval(() => {
      setCurrentTime(moment().format("HH:mm"));
      handleLeftTimeMain();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  // to display the amount of time left for next namoz time
  const findLeftTimeMain = (ms) => {
    if (!ms) {
      return "-00:00:00";
    }
    ms = Math.floor(ms / 1000);
    let hour = Math.floor(ms / 3600);
    let minute = Math.floor(ms / 60) % 60;
    let second = ms % 60;
    return (
      "-" +
      `${hour}`.padStart(2, "0") +
      ":" +
      `${minute}`.padStart(2, "0") +
      ":" +
      `${second}`.padStart(2, "0")
    );
  };
  // to make right date format
  const reverseStringMain = (str) => {
    if (str) {
      var s = str.split("-");
      return s[1] + "-" + s[0] + "-" + s[2];
    }
    return "";
  };

  // //to find am and pm
  const findAmPm = (time) => {
    if (time > "11:59:59") return "PM";
    else return "AM";
  };
  //to display the end of the dey
  const endDayMain = () => {
    let current = moment().format("MM-DD-YYYY");

    return moment(new Date(current + " 23:59:59")).format(
      "DD-MM-YYYY HH:mm:ss"
    );
  };
  // to display the start time of the day
  const beginDayMain = () => {
    let current = moment().format("MM-DD-YYYY");
    return moment(new Date(current + " 00:00:00")).format(
      "DD-MM-YYYY HH:mm:ss"
    );
  };
  // to display the list of namoz vaqtlari
  const findPrayerListMain = (data) => {
    if (data) {
      let currentDateMain = moment().format("DD-MM-YYYY");
      let pListMain = data.filter((p) => p.gregorian == currentDateMain);
      setPrayerListMain(pListMain[0]);
      setNextDayMain(data[data.indexOf(pListMain[0]) + 1]);
    } else return console.log("Internal Server error");
  };
  //to handle left time
  const handleLeftTimeMain = () => {
    let currentTimeNamozMain = moment().format("HH:mm");
    if (
      currentTimeNamozMain > prayerListMain.Sunrise &&
      currentTimeNamozMain < prayerListMain.Dhuhr
    ) {
      setCurrentNamozMain("Peshin");
      setLeftTimeMain(
        findLeftTimeMain(
          new Date(
            `${reverseStringMain(prayerListMain.gregorian)} ${
              prayerListMain.Dhuhr
            }`
          ) - new Date()
        )
      );
    } else if (
      currentTimeNamozMain > prayerListMain.Dhuhr &&
      currentTimeNamozMain < prayerListMain.Asr
    ) {
      setCurrentNamozMain("Asr");
      setLeftTimeMain(
        findLeftTimeMain(
          new Date(
            `${reverseStringMain(prayerListMain.gregorian)} ${
              prayerListMain.Asr
            }`
          ) - new Date()
        )
      );
    } else if (
      currentTimeNamozMain > prayerListMain.Asr &&
      currentTimeNamozMain < prayerListMain.Maghrib
    ) {
      setCurrentNamozMain("Shom");
      setLeftTimeMain(
        findLeftTimeMain(
          new Date(
            `${reverseStringMain(prayerListMain.gregorian)} ${
              prayerListMain.Maghrib
            }`
          ) - new Date()
        )
      );
    } else if (
      currentTimeNamozMain > prayerListMain.Maghrib &&
      currentTimeNamozMain < prayerListMain.Isha
    ) {
      setCurrentNamozMain("Hufton");
      setLeftTimeMain(
        findLeftTimeMain(
          new Date(
            `${reverseStringMain(prayerListMain.gregorian)} ${
              prayerListMain.Isha
            }`
          ) - new Date()
        )
      );
    } else if (
      currentTimeNamozMain > prayerListMain.Isha &&
      currentTimeNamozMain < endDayMain()
    ) {
      setCurrentNamozMain("Bomdod");

      let tmp = findLeftTimeMain(
        new Date(
          `${reverseStringMain(nextDayMain.gregorian)} ${nextDayMain.Fajr}`
        ) - new Date()
      );
      setLeftTimeMain(tmp);
    } else if (
      // currentTimeNamoz > prayerListMain.Isha &&
      currentTimeNamozMain > beginDayMain()
    ) {
      setCurrentNamozMain("Bomdod");

      let tmp = findLeftTimeMain(
        new Date(
          `${reverseStringMain(prayerListMain.gregorian)} ${
            prayerListMain.Fajr
          }`
        ) - new Date()
      );
      setLeftTimeMain(tmp);
    }
  };

  const getNearestNamozTime = () => {
    switch (currentNamozMain) {
      case "Bomdod":
        return prayerListMain.Fajr;
      case "Quyosh":
        return prayerListMain.Sunrise;
      case "Peshin":
        return prayerListMain.Dhuhr;
      case "Asr":
        return prayerListMain.Asr;
      case "Shom":
        return prayerListMain.Maghrib;
      case "Xufton":
        return prayerListMain.Isha;
      default:
        return "...";
    }
  };
  //to highlight the chosen namoz label
  // console.log(currentTime);
  if (prayerTime.timings && prayerTime.date)
    return (
      <div
        style={{
          backgroundColor: "#065637",
          borderBottom: "70px solid #065637",
        }}
      >
        <div className="w-100 main_class">
          <div className="row0 d-flex w-100 ">
            <div className="row0_left">
              <h6 style={{ fontWeight: "bold" }}>{`${
                prayerTime.date.gregorian.day
              }- ${monthConverter(prayerTime)}`}</h6>
              <h6>
                {prayerTime.date.hijri.year}-yil {prayerTime.date.hijri.day}
                {""} {prayerTime.date.hijri.month.en}
              </h6>
            </div>
            <div className="row0_right">
              <h4>{currentNamozMain}</h4>
              <h3>{getNearestNamozTime()}</h3>
              <h6>{leftTimeMain}</h6>
              <h6>
                Tashkent, Uzbekistan
                <span>
                  <img
                    src={locationIcon}
                    alt="location"
                    className="locationIcon"
                  />
                </span>
              </h6>
            </div>
          </div>
          <div className="row1 d-flex justify-content-end">
            <div
              className=" namoz_vaqtlari_div"
              onClick={() => history.push("/namozVaqtlari")}
            >
              <img className="namozvaqtiBack" src={namozvaqtiBack} />
            </div>
            <div
              className=" muallimi_soni_div"
              onClick={() => history.push("/muallimiSoniy")}
            >
              <img className="muallimisoniyBack" src={muallimisoniyBack} />
            </div>
          </div>
          <div className="row2 d-flex justify-content-end">
            <div className=" quron_div" onClick={() => history.push("/quron")}>
              <img src={quronBack} className="quronBack" />
            </div>
            <div
              className=" zikrlar_div "
              onClick={() => history.push("/zikirlarmain")}
            >
              <img src={zikrlarBack} className="zikrlarBack" />
            </div>
          </div>
        </div>
      </div>
    );
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
      }}
    >
      <Loader
        color="rgb(247,194,68)"
        type="Puff"
        height={150}
        width={150}
        timeout={3000} //3 secs
      />
    </div>
  );
};

export default withRouter(MainPage);
