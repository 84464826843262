import React from "react";
import { Row, Col } from "antd";
import Loader from "react-loader-spinner";
import "../tungizikrlar/tungizikrlar.css";
import { PlayCircleOutlined } from "@ant-design/icons";
import listIcon from "../../../images/listIcon.png";
import greyIconList from "../../../images/list_icon_grey.png";
import playingIcon from "../../../images/playingIcon.png";
import stopedIcon from "../../../images/stopedIcon.png";
const TungiZikirlarList = ({
  getTungiZikirlarActiveId,
  tungizikirlarList,
  handlePlayTungiZikirlar,
}) => {
  if (!tungizikirlarList || tungizikirlarList.lenght === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Loader
          color="#00B273"
          type="Puff"
          height={150}
          width={150}
          timeout={3000} //3 secs
        />
      </div>
    );
  }
  return (
    <div style={{ padding: "5px" }}>
      <div style={{ padding: "5px" }}>
        {tungizikirlarList.map((tungizikr) => (
          <div
            key={tungizikr.id}
            style={
              getTungiZikirlarActiveId == tungizikr.id
                ? {
                    height: "100%",
                    width: "100%",
                    borderBottom: "1px solid ",
                    background: "#00B273",
                    borderColor: "rgba(0, 0, 0, 0.2)",
                  }
                : {
                    height: "100%",
                    width: "100%",
                    borderBottom: "1px solid ",
                    borderColor: "rgba(0, 0, 0, 0.2)",
                  }
            }
          >
            <Row style={{ display: "flex" }}>
              <div
                onClick={() => handlePlayTungiZikirlar(tungizikr)}
                style={{
                  display: "flex",
                  width: "100%",
                  cursor: "pointer",
                }}
              >
                <div>
                  {getTungiZikirlarActiveId == tungizikr.id ? (
                    <img
                      className="icon-tungi"
                      src={listIcon}
                      style={{ padding: "10px" }}
                      alt="fdd"
                    />
                  ) : (
                    <img
                      className="icon-tungi"
                      src={greyIconList}
                      style={{ padding: "10px" }}
                      alt="fddd"
                    />
                  )}
                </div>
                <div
                  style={
                    getTungiZikirlarActiveId == tungizikr.id
                      ? {
                          fontSize: "21px",
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }
                      : {
                          fontSize: "21px",
                          color: "#000",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }
                  }
                >
                  {tungizikr.name}
                </div>
                <div className="list_content_tungi">
                  <div className="content_inner_tungi">
                    <div>
                      {getTungiZikirlarActiveId == tungizikr.id ? (
                        <img
                          src={stopedIcon}
                          style={{ padding: "10px" }}
                          alt="fdd"
                        />
                      ) : (
                        <img
                          src={playingIcon}
                          style={{ padding: "10px" }}
                          alt="fddd"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TungiZikirlarList;
