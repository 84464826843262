import axios from "axios";
const base_url = "https://api.al-quran.uz/";
const harflarList_url = base_url + "list/" + 2;
const tajvidList_url = base_url + "list/" + 4;
const kalimaList_url = base_url + "list/" + 3;
const suralarList_url = base_url + "list/" + 6;
const zikirlarList_url = base_url + "list/" + 11;
const tungizikirlarList_url = base_url + "list/" + 12;
const quronList_url = base_url + "list/" + 8;

export const getNamozVaqtlari = (month, year) => {
  month = parseInt(month);
  let namoz_vaqtlari = `https://api.al-quran.uz/namaz?latitude=41.3505923&longitude=69.2194397&method=2&month=${month}&year=${year}&school=1`;
  return axios.get(namoz_vaqtlari);
};
export {
  base_url,
  harflarList_url,
  tajvidList_url,
  kalimaList_url,
  suralarList_url,
  zikirlarList_url,
  tungizikirlarList_url,
  quronList_url,
};
