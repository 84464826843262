import React from "react";
import ReactHlsPlayer from "react-hls-player";
import "../tongizikrlar/tongizikrlar.css";
const TongiZikirlarVideoPlayer = ({ url, playerRef }) => {
  // console.log(url);
  return (
    <div>
      <div className=" container player-wrapper">
        <ReactHlsPlayer
          style={{ border: "1px solid black" }}
          url={`https://cdn.al-quran.uz/11/${
            url.uuid || "75d32680-1597-5b42-a0ab-43bc2574a608"
          }`}
          className="react-player-tongi"
          playerRef={playerRef}
          autoPlay={true}
          controls={true}
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default TongiZikirlarVideoPlayer;
