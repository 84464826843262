import React from "react";
import { Row, Col } from "antd";
import Loader from "react-loader-spinner";
import "../suralar/suralar.css";
import { PlayCircleOutlined } from "@ant-design/icons";
import listIcon from "../../../images/listIcon.png";
import greyIconList from "../../../images/list_icon_grey.png";
import playingIcon from "../../../images/playingIcon.png";
import stopedIcon from "../../../images/stopedIcon.png";
const SuralarList = ({
  getSuralarActiveId,
  suralarList,
  handlePlaySuralar,
}) => {
  if (!suralarList || suralarList.lenght === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <Loader
          color="#00B273"
          type="Puff"
          height={150}
          width={150}
          timeout={3000} //3 secs
        />
      </div>
    );
  }
  return (
    <div style={{ padding: "5px" }}>
      {suralarList.map((sura) => (
        <div
          key={sura.id}
          style={
            getSuralarActiveId == sura.id
              ? {
                  height: "100%",
                  width: "100%",
                  borderBottom: "1px solid ",
                  background: "#00B273",
                  borderColor: "rgba(0, 0, 0, 0.2)",
                }
              : {
                  height: "100%",
                  width: "100%",
                  borderBottom: "1px solid ",
                  borderColor: "rgba(0, 0, 0, 0.2)",
                }
          }
        >
          <Row style={{ display: "flex" }}>
            <div
              onClick={() => handlePlaySuralar(sura)}
              style={{
                display: "flex",
                width: "100%",
                cursor: "pointer",
              }}
            >
              <div>
                {getSuralarActiveId == sura.id ? (
                  <img src={listIcon} style={{ padding: "10px" }} alt="fdd" />
                ) : (
                  <img
                    src={greyIconList}
                    style={{ padding: "10px" }}
                    alt="fddd"
                  />
                )}
              </div>
              <div
                className="suralar_list"
                style={
                  getSuralarActiveId == sura.id
                    ? {
                        color: "#fff",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }
                    : {
                        color: "#000",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }
                }
              >
                {sura.name}
              </div>
              <div className="list_content_suralar">
                <div
                  className="list_content_suralar"
                  // onClick={() => handlePlaySuralar(sura)}
                >
                  <div>
                    {getSuralarActiveId == sura.id ? (
                      <img
                        src={stopedIcon}
                        style={{ padding: "10px" }}
                        alt="fdd"
                      />
                    ) : (
                      <img
                        src={playingIcon}
                        style={{ padding: "10px" }}
                        alt="fddd"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default SuralarList;
