import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Row, Col, Button, Layout } from "antd";
import "../quron/quron.css";
import logoSvg from "../../images/logosvg.svg";
import { quronList_url } from "../../constants/BaseUrl";
import QuronVideoPlayer from "../quron/QuronVideoPlayer";
import QuronList from "./QuronList";

const Quron = ({ history }) => {
  const [quronList, setQuronList] = useState();
  const [getQuronUrl, setQuronUrl] = useState("");
  const [getQuronActiveId, setQuronActiveId] = useState(97);
  const { Content } = Layout;
  const playerRef = useRef();

  useEffect(async () => {
    const res = await axios.get(quronList_url);
    setQuronList(res.data.data);
  }, [setQuronList]);

  // const handlePlayQuron = (url) => {
  //   setQuronUrl(url);
  //   setQuronActiveId(url.id);
  // };
  const handlePlayQuron = (url) => {
    if (url.id === getQuronActiveId) {
      if (playerRef.current) {
        const video = playerRef.current;
        const isVideoPlaying = !!(
          video.currentTime > 0 &&
          !video.paused &&
          !video.ended &&
          video.readyState > 2
        );
        if (isVideoPlaying) {
          video.pause();
        } else {
          video.play();
        }
      }
    } else {
      setQuronUrl(url);
      setQuronActiveId(url.id);
    }
  };

  return (
    <div>
      <div className="logo_div2">
        <img
          src={logoSvg}
          className="alquran_logo2"
          onClick={() => history.push("/")}
        />
      </div>
      <Row className="d-flex w-100 quran_main_div">
        <Col xs={24} lg={18}>
          <div className="d-flex quran_main_nav_div">
            <div>Quron suralar</div>
          </div>
          <div style={{ marginTop: "3%" }}>
            <Content>
              <QuronVideoPlayer playerRef={playerRef} url={getQuronUrl} />
            </Content>
          </div>
        </Col>
        <Col xs={24} lg={6}>
          <div className="quronVideoList">
            <div
              className="quran_list_title"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "",
                // border: "2px solid red",
              }}
            >
              <h5 className="quron-suralari">Quron suralar</h5>
            </div>
            <div
              style={{
                overflowY: "auto",
                height: "90vh",
                width: "95%",
                margin: "0 auto",
              }}
            >
              <Content>
                <QuronList
                  getQuronActiveId={getQuronActiveId}
                  quronList={quronList}
                  handlePlayQuron={handlePlayQuron}
                />
              </Content>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Quron;
